import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format, parseISO } from 'date-fns';
import type { PriceHistoryEntry } from '../types';

interface PriceHistoryProps {
  history: PriceHistoryEntry[];
}

export const PriceHistory: React.FC<PriceHistoryProps> = ({ history }) => {
  if (!history || history.length === 0) {
    return (
      <div className="h-64 flex items-center justify-center text-gray-500">
        No price history available
      </div>
    );
  }

  // Transform the data to match the actual database structure
  const data = history.map(entry => ({
    date: entry.date,
    walmart: entry.walmart || 0,
    amazon: entry.amazon || 0,
    ebay: entry.ebay || 0
  }));

  return (
    <div className="h-64 w-full">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date"
            tickFormatter={(date) => format(parseISO(date), 'MMM d')}
          />
          <YAxis 
            tickFormatter={(value) => `$${value}`}
          />
          <Tooltip 
            labelFormatter={(date) => format(parseISO(date as string), 'MMM d, yyyy')}
            formatter={(value, name) => [`$${value}`, name]}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="walmart"
            stroke="#0071dc"
            name="Walmart"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="amazon"
            stroke="#ff9900"
            name="Amazon"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="ebay"
            stroke="#e53238"
            name="eBay"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};