import React from 'react';
import { Link } from 'react-router-dom';
import type { Product } from '../types';

interface ProductCardProps {
  product: Product;
}

export const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const lowestPrice = Math.min(
    ...[
      product.prices.walmart?.price || Infinity,
      product.prices.amazon?.price || Infinity,
      product.prices.ebay?.price || Infinity
    ]
  );

  const getStoreCount = () => {
    return Object.values(product.prices).filter(p => p?.price > 0).length;
  };

  return (
    <Link 
      to={`/product/${product.slug}`}
      className="group relative"
    >
      <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200">
        <img
          src={product.images[0]}
          alt={product.name}
          className="h-full w-full object-cover object-center group-hover:opacity-75"
        />
      </div>
      <div className="mt-4 flex justify-between">
        <div>
          <h3 className="text-sm text-gray-700">{product.name}</h3>
          <p className="mt-1 text-sm text-gray-500">{product.brand}</p>
        </div>
        <div className="text-right">
          <p className="text-sm font-medium text-gray-900">
            From ${lowestPrice.toFixed(2)}
          </p>
          <p className="text-xs text-gray-500">
            Available at {getStoreCount()} {getStoreCount() === 1 ? 'store' : 'stores'}
          </p>
        </div>
      </div>
    </Link>
  );
};