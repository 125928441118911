import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { db } from '../../lib/firebase';
import type { Product } from '../../types';
import { StoreButton } from '../../components/StoreButton';
import { Helmet } from 'react-helmet-async';

export const ComparisonPage = () => {
  const { slugs } = useParams();
  const [slug1, slug2] = (slugs || '').split('-vs-');

  const { data: products, isLoading, error } = useQuery({
    queryKey: ['products', slug1, slug2],
    queryFn: async () => {
      // Get all products
      const snapshot = await get(ref(db, 'products'));
      const allProducts = snapshot.val() as Record<string, Product>;
      
      // Find products by slug
      const productArray = Object.values(allProducts);
      const product1 = productArray.find(p => p.slug === slug1);
      const product2 = productArray.find(p => p.slug === slug2);

      if (!product1 || !product2) {
        throw new Error('One or both products not found');
      }

      return { product1, product2 };
    }
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-lg">Loading comparison...</div>
      </div>
    );
  }

  if (error || !products) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-lg text-red-600">
          Error loading products. Please try again later.
          {error instanceof Error && <div className="text-sm mt-2">{error.message}</div>}
        </div>
      </div>
    );
  }

  const { product1, product2 } = products;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <Helmet>
        <title>{`${product1.name} vs ${product2.name} - Comparison`}</title>
        <meta name="description" content={`Compare ${product1.name} vs ${product2.name} - prices, features, and specifications`} />
      </Helmet>

      <h1 className="text-3xl font-bold text-gray-900 mb-8">
        {product1.name} vs {product2.name}
      </h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Product 1 */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <img 
            src={product1.images[0]} 
            alt={product1.name}
            className="w-full h-64 object-contain mb-4"
          />
          <h2 className="text-xl font-semibold mb-2">{product1.name}</h2>
          <p className="text-gray-600 mb-4">{product1.description}</p>
          
          <div className="mt-4">
            <h3 className="font-medium mb-2">Available at:</h3>
            {Object.entries(product1.prices)
              .filter(([_, price]) => price && price.price > 0)
              .map(([store, price]) => (
                <StoreButton
                  key={store}
                  store={store}
                  price={price.price}
                  productName={product1.name}
                  affiliateUrl={store.toLowerCase() === 'walmart' ? price.affiliateUrl : price.url}
                />
              ))}
          </div>
        </div>

        {/* Product 2 */}
        <div className="bg-white rounded-lg shadow-sm p-6">
          <img 
            src={product2.images[0]} 
            alt={product2.name}
            className="w-full h-64 object-contain mb-4"
          />
          <h2 className="text-xl font-semibold mb-2">{product2.name}</h2>
          <p className="text-gray-600 mb-4">{product2.description}</p>
          
          <div className="mt-4">
            <h3 className="font-medium mb-2">Available at:</h3>
            {Object.entries(product2.prices)
              .filter(([_, price]) => price && price.price > 0)
              .map(([store, price]) => (
                <StoreButton
                  key={store}
                  store={store}
                  price={price.price}
                  productName={product2.name}
                  affiliateUrl={store.toLowerCase() === 'walmart' ? price.affiliateUrl : price.url}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}; 