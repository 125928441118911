import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyCG5jggIjUcg6qxdJ5X6F3BlHwUq7w2BGw",
  authDomain: "fooddehydratorcritic.firebaseapp.com",
  projectId: "fooddehydratorcritic",
  storageBucket: "fooddehydratorcritic.firebasestorage.app",
  messagingSenderId: "579678788919",
  appId: "1:579678788919:web:c970d66d7a2dd856840eb2",
  databaseURL: "https://fooddehydratorcritic-default-rtdb.firebaseio.com"
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);