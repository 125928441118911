import React, { useState } from 'react';
import type { Product } from '../../types';
import { PriceHistory } from '../../components/PriceHistory';

interface TabsProps {
  product: Product;
}

export const Tabs = ({ product }: TabsProps) => {
  const [activeTab, setActiveTab] = useState('description');

  // Ensure reviews is always an array
  const reviews = Array.isArray(product.reviews) ? product.reviews : [];

  return (
    <div>
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {['description', 'specifications', 'reviews', 'price history'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`
                whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                ${activeTab === tab
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }
              `}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </nav>
      </div>

      <div className="mt-8">
        {activeTab === 'description' && (
          <div className="prose max-w-none">
            {product.description}
          </div>
        )}

        {activeTab === 'specifications' && (
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            {Object.entries(product.specifications || {}).map(([key, value]) => (
              <div key={key}>
                <dt className="font-medium text-gray-900">{key}</dt>
                <dd className="mt-1 text-gray-500">{value}</dd>
              </div>
            ))}
          </dl>
        )}

        {activeTab === 'reviews' && (
          <div>
            {reviews.length > 0 ? (
              reviews.map((review) => (
                <div key={review.id} className="mb-4">
                  <div className="flex items-center">
                    <span className="text-yellow-400">★</span>
                    <span className="ml-1">{review.rating}</span>
                  </div>
                  <p className="mt-1">{review.text}</p>
                  <p className="text-sm text-gray-500">
                    By {review.author} on {new Date(review.date).toLocaleDateString()}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No reviews yet</p>
            )}
          </div>
        )}

        {activeTab === 'price history' && (
          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4">Price History</h3>
            {Array.isArray(product.priceHistory) && product.priceHistory.length > 0 ? (
              <PriceHistory history={product.priceHistory} />
            ) : (
              <p className="text-gray-500">No price history available yet</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}; 