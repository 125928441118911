import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ref, get } from 'firebase/database';
import { db } from '../../lib/firebase';
import { ProductCard } from '../../components/ProductCard';
import type { Product } from '../../types';

export const Search = () => {
  const [searchParams] = useSearchParams();
  const brandFilter = searchParams.get('brand');

  const { data: products, isLoading } = useQuery({
    queryKey: ['products', 'search', brandFilter],
    queryFn: async () => {
      const productsRef = ref(db, 'products');
      const snapshot = await get(productsRef);
      const allProducts: Product[] = [];

      snapshot.forEach((child) => {
        const product = child.val() as Product;
        
        // Apply brand filter if present
        if (brandFilter && product.brand !== brandFilter) {
          return;
        }
        
        allProducts.push(product);
      });

      // Sort by price (lowest first)
      return allProducts.sort((a, b) => {
        const aPrice = Math.min(...Object.values(a.prices || {}).map(p => p?.price || Infinity));
        const bPrice = Math.min(...Object.values(b.prices || {}).map(p => p?.price || Infinity));
        return aPrice - bPrice;
      });
    }
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-900">
          {brandFilter ? `${brandFilter} Products` : 'All Products'}
        </h1>
        <div className="text-sm text-gray-500">
          {products?.length} products found
        </div>
      </div>

      {/* Filters Section */}
      <div className="grid grid-cols-4 gap-8">
        <div className="col-span-1 bg-white rounded-lg shadow-sm p-6">
          <h2 className="font-medium text-gray-900 mb-4">Filters</h2>
          
          {/* Price Range Filter */}
          <div className="mb-6">
            <h3 className="text-sm font-medium text-gray-900 mb-2">Price Range</h3>
            <div className="space-y-2">
              <label className="flex items-center">
                <input type="checkbox" className="rounded border-gray-300" />
                <span className="ml-2 text-sm text-gray-600">Under $500</span>
              </label>
              <label className="flex items-center">
                <input type="checkbox" className="rounded border-gray-300" />
                <span className="ml-2 text-sm text-gray-600">$500 - $1000</span>
              </label>
              <label className="flex items-center">
                <input type="checkbox" className="rounded border-gray-300" />
                <span className="ml-2 text-sm text-gray-600">Over $1000</span>
              </label>
            </div>
          </div>

          {/* Store Filter */}
          <div>
            <h3 className="text-sm font-medium text-gray-900 mb-2">Store</h3>
            <div className="space-y-2">
              <label className="flex items-center">
                <input type="checkbox" className="rounded border-gray-300" />
                <span className="ml-2 text-sm text-gray-600">Walmart</span>
              </label>
              <label className="flex items-center">
                <input type="checkbox" className="rounded border-gray-300" />
                <span className="ml-2 text-sm text-gray-600">Amazon</span>
              </label>
              <label className="flex items-center">
                <input type="checkbox" className="rounded border-gray-300" />
                <span className="ml-2 text-sm text-gray-600">eBay</span>
              </label>
            </div>
          </div>
        </div>

        {/* Products Grid */}
        <div className="col-span-3">
          <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3">
            {products?.map((product) => (
              <ProductCard key={product.slug} product={product} />
            ))}
          </div>

          {products?.length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-500">No products found matching your criteria.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}; 