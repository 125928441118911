import React from 'react';
import { Link } from 'react-router-dom';

export const Navigation: React.FC = () => (
  <nav className="hidden md:flex gap-6">
    <Link to="/" className="hover:text-blue-600">Home</Link>
    <Link to="/brands" className="hover:text-blue-600">Brands</Link>
    <Link to="/compare" className="hover:text-blue-600">Compare</Link>
    <Link to="/reviews" className="hover:text-blue-600">Reviews</Link>
    <Link to="/blog" className="hover:text-blue-600">Blog</Link>
  </nav>
);