import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ref, get } from 'firebase/database';
import { db } from '../../lib/firebase';
import { ProductCard } from '../../components/ProductCard';
import { PriceRangeFilter } from '../../components/PriceRangeFilter';
import type { Product } from '../../types';
import { SEO } from '../../components/SEO';

export const Home = () => {
  const [searchParams] = useSearchParams();
  const minPrice = Number(searchParams.get('minPrice')) || 0;
  const maxPrice = Number(searchParams.get('maxPrice')) || Infinity;

  const { data: products, isLoading } = useQuery({
    queryKey: ['products', minPrice, maxPrice],
    queryFn: async () => {
      const productsRef = ref(db, 'products');
      const snapshot = await get(productsRef);
      const products: Product[] = [];
      
      snapshot.forEach((child) => {
        const product = child.val() as Product;
        const lowestPrice = Math.min(
          ...Object.values(product.prices)
            .filter(p => p?.price)
            .map(p => p!.price)
        );
        
        if (lowestPrice >= minPrice && lowestPrice <= maxPrice) {
          products.push(product);
        }
      });
      
      return products;
    }
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <>
      <SEO 
        title="Compare Prices On The Best Selling Food Dehydrators"
        description="Find the best deals on food dehydrators from top brands. Compare prices across Walmart, Amazon, and eBay to get the lowest price on your perfect food dehyradtion machine."
      />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-6">
          Compare Prices On The Best Selling Food Dehydrators
        </h1>
        
        <div className="prose prose-lg max-w-none mb-12">
          <p>
            Welcome to Food Dehydrator Critic, your trusted resource for finding the perfect Food Dehydrator at the best price. 
            We compare prices across major retailers including Walmart, Amazon, and eBay to help you make an informed decision 
            and get the best deal on your next Food Dehydrator machine.
          </p>
          <p>
            Whether you're a beginner looking for your first machine or an experienced foodie seeking a professional-grade model, 
            our comprehensive price comparison tools and detailed product reviews will help you find exactly what you need at 
            the lowest price available.
          </p>
        </div>

        <PriceRangeFilter />

        <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4">
          {products?.map((product) => (
            <ProductCard key={product.slug} product={product} />
          ))}
        </div>

        {products?.length === 0 && (
          <div className="text-center py-12">
            <p className="text-gray-500">No products found in this price range.</p>
          </div>
        )}
      </div>
    </>
  );
};