import { useSearchParams } from 'react-router-dom';

const PRICE_RANGES = [
  { label: 'Under $1,000', min: 0, max: 1000 },
  { label: '$1,000 - $3,000', min: 1000, max: 3000 },
  { label: '$3,000 - $5,000', min: 3000, max: 5000 },
  { label: 'Over $5,000', min: 5000, max: Infinity }
];

export const PriceRangeFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentMin = searchParams.get('minPrice');
  const currentMax = searchParams.get('maxPrice');

  const handleRangeClick = (min: number, max: number) => {
    const newParams = new URLSearchParams(searchParams);
    if (min === 0 && max === Infinity) {
      newParams.delete('minPrice');
      newParams.delete('maxPrice');
    } else {
      newParams.set('minPrice', min.toString());
      newParams.set('maxPrice', max.toString());
    }
    setSearchParams(newParams);
  };

  const isSelected = (min: number, max: number) => {
    return currentMin === min.toString() && currentMax === max.toString();
  };

  return (
    <div className="mb-8">
      <h2 className="text-lg font-medium text-gray-900 mb-4">Price Range</h2>
      <div className="flex flex-wrap gap-2">
        <button
          onClick={() => handleRangeClick(0, Infinity)}
          className={`px-4 py-2 rounded-full text-sm font-medium ${
            !currentMin && !currentMax
              ? 'bg-indigo-600 text-white'
              : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
          }`}
        >
          All Prices
        </button>
        {PRICE_RANGES.map(({ label, min, max }) => (
          <button
            key={label}
            onClick={() => handleRangeClick(min, max)}
            className={`px-4 py-2 rounded-full text-sm font-medium ${
              isSelected(min, max)
                ? 'bg-indigo-600 text-white'
                : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
            }`}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
}; 