import React from 'react';

interface PriceComparisonProps {
  prices: {
    walmart?: {
      affiliateUrl: string;
      price: number;
      lastUpdated: string;
      store: string;
    };
    amazon?: {
      url: string;
      price: number;
      lastUpdated: string;
    };
    ebay?: {
      url: string;
      price: number;
      lastUpdated: string;
    };
  };
}

const STORE_CONFIGS = {
  walmart: {
    logo: 'https://i5.walmartimages.com/dfw/63fd9f59-b3e1/7a569e53-f29a-4c3d-bfaf-6f7a158bfadd/v1/walmartLogo.svg',
    bgColor: 'bg-[#0071DC]',
    hoverColor: 'hover:bg-[#004F9A]'
  },
  amazon: {
    logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg',
    bgColor: 'bg-[#FF9900]',
    hoverColor: 'hover:bg-[#E88B00]'
  },
  ebay: {
    logo: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/EBay_logo.svg',
    bgColor: 'bg-[#E53238]',
    hoverColor: 'hover:bg-[#CC2D32]'
  }
};

export const PriceComparison: React.FC<PriceComparisonProps> = ({ prices }) => {
  // Calculate best savings
  const calculateSavings = () => {
    const validPrices = [
      prices.walmart?.price,
      prices.amazon?.price,
      prices.ebay?.price
    ].filter((price): price is number => 
      typeof price === 'number' && !isNaN(price) && price > 0
    );

    if (validPrices.length < 2) return null;

    const highest = Math.max(...validPrices);
    const lowest = Math.min(...validPrices);
    const savings = highest - lowest;

    return savings > 0 ? savings : null;
  };

  const savings = calculateSavings();

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-xl font-bold">Compare Prices</h2>
      <div className="grid gap-4">
        {prices?.walmart?.affiliateUrl && (
          <a 
            href={prices.walmart.affiliateUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={`flex items-center justify-between px-6 py-4 rounded-lg text-white ${STORE_CONFIGS.walmart.bgColor} ${STORE_CONFIGS.walmart.hoverColor} transition-colors`}
          >
            <div className="flex items-center gap-4">
              <img 
                src={STORE_CONFIGS.walmart.logo} 
                alt="Walmart" 
                className="h-8 w-auto"
              />
              <span className="text-lg font-medium">Buy from Walmart</span>
            </div>
            <span className="text-xl font-bold">${prices.walmart.price}</span>
          </a>
        )}

        {prices?.amazon?.url && prices.amazon.url !== "" && (
          <a 
            href={prices.amazon.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`flex items-center justify-between px-6 py-4 rounded-lg text-white ${STORE_CONFIGS.amazon.bgColor} ${STORE_CONFIGS.amazon.hoverColor} transition-colors`}
          >
            <div className="flex items-center gap-4">
              <img 
                src={STORE_CONFIGS.amazon.logo} 
                alt="Amazon" 
                className="h-8 w-auto bg-white p-1 rounded"
              />
              <span className="text-lg font-medium">Buy from Amazon</span>
            </div>
            <span className="text-xl font-bold">${prices.amazon.price}</span>
          </a>
        )}

        {prices?.ebay?.url && prices.ebay.url !== "" && (
          <a 
            href={prices.ebay.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`flex items-center justify-between px-6 py-4 rounded-lg text-white ${STORE_CONFIGS.ebay.bgColor} ${STORE_CONFIGS.ebay.hoverColor} transition-colors`}
          >
            <div className="flex items-center gap-4">
              <img 
                src={STORE_CONFIGS.ebay.logo} 
                alt="eBay" 
                className="h-8 w-auto bg-white p-1 rounded"
              />
              <span className="text-lg font-medium">Buy from eBay</span>
            </div>
            <span className="text-xl font-bold">${prices.ebay.price}</span>
          </a>
        )}

        {savings && savings > 0 && (
          <div className="mt-2 text-center">
            <p className="text-green-600 font-semibold text-lg">
              Save ${savings.toFixed(2)} by comparing prices!
            </p>
            <p className="text-sm text-gray-600">
              Prices may vary. Always check final price before purchase.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}; 