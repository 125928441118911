import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ref, get } from 'firebase/database';
import { db } from '../../lib/firebase';
import type { BlogPost as BlogPostType } from '../../types/blog';
import { SEO } from '../../components/SEO';

export const BlogPost = () => {
  const { slug } = useParams<{ slug: string }>();

  const { data: post, isLoading } = useQuery<BlogPostType>({
    queryKey: ['blog-post', slug],
    queryFn: async () => {
      const postsRef = ref(db, 'blog-posts');
      const snapshot = await get(postsRef);
      let foundPost: BlogPostType | null = null;
      
      snapshot.forEach((child) => {
        const post = child.val() as BlogPostType;
        if (post.slug === slug) {
          foundPost = post;
        }
      });
      
      if (!foundPost) throw new Error('Post not found');
      return foundPost;
    }
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!post) return null;

  return (
    <>
      <SEO 
        title={post.title}
        description={post.excerpt}
        type="article"
        imageUrl={post.imageUrl}
      />
      <article className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <header className="text-center mb-12">
          <div className="flex items-center justify-center space-x-2 text-sm text-gray-500 mb-4">
            <time dateTime={post.publishedAt}>
              {new Date(post.publishedAt).toLocaleDateString()}
            </time>
            <span>·</span>
            <span>{post.readingTime}</span>
            <span>·</span>
            <span>{post.category}</span>
          </div>
          
          <h1 className="text-4xl font-bold text-gray-900 mb-8">
            {post.title}
          </h1>

          <div className="flex items-center justify-center">
            <img
              src={post.author.avatar}
              alt={post.author.name}
              className="h-10 w-10 rounded-full"
            />
            <div className="ml-3 text-left">
              <p className="text-sm font-medium text-gray-900">
                {post.author.name}
              </p>
            </div>
          </div>
        </header>

        <img
          src={post.imageUrl}
          alt={post.title}
          className="w-full h-96 object-cover rounded-lg mb-12"
        />

        <div 
          className="prose prose-lg max-w-none"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />

        <div className="mt-8 flex flex-wrap gap-2">
          {post.tags.map(tag => (
            <span
              key={tag}
              className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800"
            >
              {tag}
            </span>
          ))}
        </div>
      </article>
    </>
  );
}; 