import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ref, get } from 'firebase/database';
import { db } from '../../lib/firebase';
import type { Product } from '../../types';
import { ComparisonCard } from '../../components/ComparisonCard';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

export const Compare = () => {
  const navigate = useNavigate();
  const { data: products, isLoading } = useQuery({
    queryKey: ['products'],
    queryFn: async () => {
      const snapshot = await get(ref(db, 'products'));
      return snapshot.val() as Record<string, Product>;
    }
  });

  const [selectedProduct1, setSelectedProduct1] = React.useState('');
  const [selectedProduct2, setSelectedProduct2] = React.useState('');

  const handleCompare = () => {
    if (selectedProduct1 && selectedProduct2) {
      const product1 = Object.values(products || {}).find(p => p.id === selectedProduct1);
      const product2 = Object.values(products || {}).find(p => p.id === selectedProduct2);
      if (product1 && product2) {
        navigate(`/compare/${product1.slug}-vs-${product2.slug}`);
      }
    }
  };

  if (isLoading) {
    return <div>Loading products...</div>;
  }

  const productList = Object.values(products || {});

  // Get some popular comparisons
  const popularComparisons = [
    { name: 'Entry Level vs Premium', products: productList.slice(0, 2) },
    { name: 'Best Sellers', products: productList.slice(2, 4) },
    { name: 'Budget Options', products: productList.slice(4, 6) }
  ].filter(comparison => comparison.products.length === 2);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <Helmet>
        <title>Compare Food Dehydrators | Food Dehydrator Critic</title>
        <meta name="description" content="Compare sewing machines side by side - prices, features, and specifications" />
      </Helmet>

      <h1 className="text-3xl font-bold text-gray-900 mb-8">Compare Food Dehydrators</h1>

      <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Select Products to Compare</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <select
            className="p-2 border rounded"
            value={selectedProduct1}
            onChange={(e) => setSelectedProduct1(e.target.value)}
          >
            <option value="">Select first product</option>
            {productList.map(product => (
              <option key={product.id} value={product.id}>
                {product.name}
              </option>
            ))}
          </select>
          <select
            className="p-2 border rounded"
            value={selectedProduct2}
            onChange={(e) => setSelectedProduct2(e.target.value)}
          >
            <option value="">Select second product</option>
            {productList
              .filter(p => p.id !== selectedProduct1)
              .map(product => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
          </select>
        </div>
        <button
          className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 disabled:bg-gray-400"
          onClick={handleCompare}
          disabled={!selectedProduct1 || !selectedProduct2}
        >
          Compare Products
        </button>
      </div>

      <h2 className="text-2xl font-bold text-gray-900 mb-6">Popular Comparisons</h2>
      <div className="space-y-8">
        {popularComparisons.map((comparison, index) => (
          <div key={index}>
            <h3 className="text-xl font-semibold mb-4">{comparison.name}</h3>
            <ComparisonCard product1={comparison.products[0]} product2={comparison.products[1]} />
          </div>
        ))}
      </div>
    </div>
  );
};