import React from 'react';

interface StoreButtonProps {
  store: string;
  price: number;
  productName: string;
  affiliateUrl?: string;
}

export const StoreButton: React.FC<StoreButtonProps> = ({ 
  store, 
  price, 
  productName,
  affiliateUrl 
}) => {
  const storeName = store.toLowerCase();

  return (
    <a
      href={affiliateUrl || '#'}
      target="_blank"
      rel="noopener noreferrer"
      className={`flex items-center justify-between w-full px-4 py-3 mb-2 text-white rounded ${
        storeName === 'walmart' 
          ? 'bg-[#0071DC]' 
          : 'bg-[#FF9900]'
      }`}
    >
      <div className="flex items-center">
        {storeName === 'walmart' ? (
          <div className="flex items-center mr-2">
            <span className="text-xl font-bold">Walmart</span>
            <svg className="w-6 h-6 ml-1" viewBox="0 0 32 32">
              <path fill="currentColor" d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zM8.862 19.127l.258-1.036h1.683l-.258 1.036h-1.683zm-3.736 0l.258-1.036h1.683l-.258 1.036H5.126zm7.473 0l.258-1.036h1.683l-.258 1.036h-1.683zm3.736 0l.258-1.036h1.683l-.258 1.036h-1.683zm3.736 0l.258-1.036h1.683l-.258 1.036h-1.683z"/>
            </svg>
          </div>
        ) : (
          <div className="flex items-center mr-2">
            <img src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg" 
                 alt="Amazon" 
                 className="h-6" />
          </div>
        )}
        <span>Buy from {store}</span>
      </div>
      <span>${price.toFixed(2)}</span>
    </a>
  );
}; 