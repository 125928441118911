import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Home } from './pages/Home';
import { ProductDetails } from './pages/ProductDetails';
import { Compare } from './pages/Compare';
import { ComparisonPage } from './pages/Compare/ComparisonPage';
import { Reviews } from './pages/Reviews';
import { Search } from './pages/Search';
import { Brands } from './pages/Brands';
import { Blog } from './pages/Blog';
import { BlogPost } from './pages/Blog/BlogPost';
import ReactGA from 'react-ga4';

const queryClient = new QueryClient();

// Initialize GA4
ReactGA.initialize('G-WP3B4P0TZ6');

export const App = () => {
  useEffect(() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/product/:slug" element={<ProductDetails />} />
                <Route path="/compare" element={<Compare />} />
                <Route path="/compare/:slugs" element={<ComparisonPage />} />
                <Route path="/reviews" element={<Reviews />} />
                <Route path="/search" element={<Search />} />
                <Route path="/brands" element={<Brands />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
              </Routes>
            </main>
            <Footer />
          </div>
        </BrowserRouter>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;