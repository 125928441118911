import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { db } from '../../lib/firebase';
import type { BlogPost } from '../../types/blog';
import { SEO } from '../../components/SEO';

export const Blog = () => {
  const { data: posts, isLoading } = useQuery<BlogPost[]>({
    queryKey: ['blog-posts'],
    queryFn: async () => {
      const postsRef = ref(db, 'blog-posts');
      const snapshot = await get(postsRef);
      const posts: BlogPost[] = [];
      
      snapshot.forEach((child) => {
        posts.push(child.val() as BlogPost);
      });
      
      return posts.sort((a, b) => 
        new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
      );
    }
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <>
      <SEO 
        title="Food Dehydrators Tips, Tutorials & Guides"
        description="Learn everything about Food Dehydrators with our comprehensive guides, tutorials, and tips. Perfect for beginners and experienced dryers alike."
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
        Food Dehydrators Blog
        </h1>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {posts?.map((post) => (
            <article
              key={post.id}
              className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow"
            >
              <Link to={`/blog/${post.slug}`}>
                <img
                  src={post.imageUrl}
                  alt={post.title}
                  className="w-full h-48 object-cover"
                />
              </Link>
              
              <div className="p-6">
                <div className="flex items-center text-sm text-gray-500 mb-2">
                  <time dateTime={post.publishedAt}>
                    {new Date(post.publishedAt).toLocaleDateString()}
                  </time>
                  <span className="mx-2">·</span>
                  <span>{post.readingTime}</span>
                </div>

                <Link 
                  to={`/blog/${post.slug}`}
                  className="block mt-2"
                >
                  <h2 className="text-xl font-semibold text-gray-900 hover:text-indigo-600">
                    {post.title}
                  </h2>
                  <p className="mt-3 text-gray-500 line-clamp-3">
                    {post.excerpt}
                  </p>
                </Link>

                <div className="mt-4">
                  <Link
                    to={`/blog/${post.slug}`}
                    className="text-indigo-600 hover:text-indigo-500"
                  >
                    Read more →
                  </Link>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </>
  );
}; 