import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { ref, get } from 'firebase/database';
import { db } from '../../lib/firebase';
import type { Product } from '../../types';

interface Review {
  id: string;
  productId: string;
  productName: string;
  productSlug: string;
  rating: number;
  author: string;
  date: string;
  title: string;
  content: string;
  verified: boolean;
}

export const Reviews = () => {
  const { data: reviews, isLoading } = useQuery({
    queryKey: ['reviews'],
    queryFn: async () => {
      const productsRef = ref(db, 'products');
      const snapshot = await get(productsRef);
      const reviews: Review[] = [];

      snapshot.forEach((child) => {
        const product = child.val() as Product;
        // Simulate reviews for now - in production these would come from the database
        const productReviews: Review[] = [
          {
            id: `${product.slug}-1`,
            productId: product.id,
            productName: product.name,
            productSlug: product.slug,
            rating: 4,
            author: "Sarah Johnson",
            date: "2024-02-15",
            title: "Great Food Dehydrator for beginners",
            content: "I've been using this machine for a few months now and it's perfect for my needs. Easy to set up and use, and the drying quality is excellent.",
            verified: true
          },
          {
            id: `${product.slug}-2`,
            productId: product.id,
            productName: product.name,
            productSlug: product.slug,
            rating: 5,
            author: "Michael Chen",
            date: "2024-02-10",
            title: "Professional quality machine at a great price",
            content: "This Food Dehydrator exceeded my expectations. The build quality is outstanding and it handles multiple layers of food with ease.",
            verified: true
          }
        ];
        reviews.push(...productReviews);
      });

      return reviews.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }
  });

  const renderStars = (rating: number) => {
    return [...Array(5)].map((_, index) => (
      <svg
        key={index}
        className={`h-5 w-5 ${
          index < rating ? 'text-yellow-400' : 'text-gray-300'
        }`}
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
    ));
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">
        Customer Reviews
      </h1>

      <div className="grid gap-8">
        {reviews?.map((review) => (
          <div
            key={review.id}
            className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow"
          >
            <div className="flex items-start justify-between">
              <div>
                <Link
                  to={`/product/${review.productSlug}`}
                  className="text-lg font-medium text-indigo-600 hover:text-indigo-500"
                >
                  {review.productName}
                </Link>
                <h3 className="text-lg font-medium text-gray-900 mt-2">
                  {review.title}
                </h3>
              </div>
              <div className="flex items-center">
                {renderStars(review.rating)}
              </div>
            </div>

            <div className="mt-2 text-sm text-gray-600">
              <p className="mb-4">{review.content}</p>
              <div className="flex items-center justify-between text-xs text-gray-500">
                <div className="flex items-center space-x-2">
                  <span>{review.author}</span>
                  {review.verified && (
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                      Verified Purchase
                    </span>
                  )}
                </div>
                <time dateTime={review.date}>
                  {new Date(review.date).toLocaleDateString()}
                </time>
              </div>
            </div>
          </div>
        ))}
      </div>

      {reviews?.length === 0 && (
        <div className="text-center py-12">
          <p className="text-gray-500">No reviews found.</p>
        </div>
      )}
    </div>
  );
}; 