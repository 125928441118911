import React from 'react';
import { Logo } from './Logo';
import { Navigation } from './Navigation';
import { Menu } from 'lucide-react';

export const Header: React.FC = () => (
  <header className="bg-white shadow-sm">
    <div className="container mx-auto px-4 py-4">
      <div className="flex items-center justify-between">
        <Logo />
        <Navigation />
        <button className="md:hidden">
          <Menu className="w-6 h-6" />
        </button>
      </div>
    </div>
  </header>
);