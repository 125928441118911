import React from 'react';
import { Link } from 'react-router-dom';
import type { Product } from '../types';

interface ComparisonCardProps {
  product1: Product;
  product2: Product;
}

export const ComparisonCard: React.FC<ComparisonCardProps> = ({ product1, product2 }) => {
  // Sort slugs to ensure consistent URL patterns
  const slugs = [product1.slug, product2.slug].sort();
  const comparisonUrl = `/compare/${slugs[0]}-vs-${slugs[1]}`;

  return (
    <Link 
      to={comparisonUrl}
      className="block p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
    >
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <img 
            src={product1.images[0]} 
            alt={product1.name}
            className="w-32 h-32 object-contain"
          />
          <h3 className="mt-2 text-sm font-medium text-gray-900">{product1.name}</h3>
        </div>
        <div className="mx-4 text-gray-500">VS</div>
        <div className="flex-1">
          <img 
            src={product2.images[0]} 
            alt={product2.name}
            className="w-32 h-32 object-contain"
          />
          <h3 className="mt-2 text-sm font-medium text-gray-900">{product2.name}</h3>
        </div>
      </div>
      <div className="mt-4 text-sm text-gray-600">
        Potential savings up to ${Math.abs(product1.price - product2.price).toFixed(2)}
      </div>
    </Link>
  );
}; 