import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ref, get } from 'firebase/database';
import { db } from '../../lib/firebase';
import type { Product } from '../../types';
import { Tabs } from './Tabs';
import { PriceComparison } from './PriceComparison';

export const ProductDetails = () => {
  const { slug } = useParams();
  
  const { data: product, isLoading } = useQuery({
    queryKey: ['product', slug],
    queryFn: async () => {
      const productsRef = ref(db, 'products');
      const snapshot = await get(productsRef);
      const products = snapshot.val() as Record<string, Product>;
      return Object.values(products).find(p => p.slug === slug);
    }
  });

  if (isLoading) return <div>Loading...</div>;
  if (!product) return <div>Product not found</div>;

  // Create SEO-friendly meta title and description
  const metaTitle = `${product.name} - Price Comparison & Reviews | Sewing Machine Guide`;
  const metaDescription = `Compare ${product.name} prices across Walmart, Amazon & eBay. Read reviews, check specifications, and find the best deals on ${product.brand} sewing machines.`;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {/* Add Open Graph tags for social sharing */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        {product.images[0] && <meta property="og:image" content={product.images[0]} />}
        <meta property="og:type" content="product" />
        {/* Add product structured data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": product.name,
            "image": product.images,
            "description": product.description,
            "brand": {
              "@type": "Brand",
              "name": product.brand
            },
            "offers": {
              "@type": "AggregateOffer",
              "priceCurrency": "USD",
              "lowPrice": Math.min(...Object.values(product.prices).map(p => p?.price || Infinity)),
              "highPrice": Math.max(...Object.values(product.prices).map(p => p?.price || 0)),
              "offerCount": Object.values(product.prices).filter(p => p?.price > 0).length
            }
          })}
        </script>
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Product image */}
          <div>
            <img
              src={product.images[0]}
              alt={product.name}
              className="w-full rounded-lg shadow-lg"
            />
          </div>

          {/* Product info */}
          <div>
            <h1 className="text-3xl font-bold text-gray-900">{product.name}</h1>
            <p className="mt-2 text-xl text-gray-500">{product.brand}</p>
            
            {/* Replace store buttons with PriceComparison component */}
            <div className="mt-8">
              <PriceComparison prices={product.prices} />
            </div>
          </div>
        </div>

        {/* Tabs section */}
        <div className="mt-16">
          <Tabs product={product} />
        </div>
      </div>
    </>
  );
};