import React from 'react';
import { Link } from 'react-router-dom';

export const Footer: React.FC = () => (
  <footer className="bg-gray-900 text-gray-300">
    <div className="container mx-auto px-4 py-12">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        <div>
          <h3 className="text-white font-semibold mb-4">About Us</h3>
          <p className="text-sm">Your trusted source for food dehydrator comparisons and reviews.</p>
        </div>
        <div>
          <h3 className="text-white font-semibold mb-4">Quick Links</h3>
          <ul className="space-y-2 text-sm">
            <li><Link to="/brands" className="hover:text-white">Brands</Link></li>
            <li><Link to="/compare" className="hover:text-white">Compare</Link></li>
            <li><Link to="/reviews" className="hover:text-white">Reviews</Link></li>
            <li><Link to="/blog" className="hover:text-white">Blog</Link></li>
          </ul>
        </div>
        <div>
          <h3 className="text-white font-semibold mb-4">Partners</h3>
          <ul className="space-y-2 text-sm">
            <li><a href="#" className="hover:text-white">Amazon</a></li>
            <li><a href="#" className="hover:text-white">Walmart</a></li>
            <li><a href="#" className="hover:text-white">eBay</a></li>
          </ul>
        </div>
        <div>
          <h3 className="text-white font-semibold mb-4">Contact</h3>
          <ul className="space-y-2 text-sm">
            <li>Email: contact@fooddehydratorcritic.com</li>
            <li>Follow us on social media</li>
          </ul>
        </div>
      </div>
      <div className="border-t border-gray-800 mt-8 pt-8 text-sm text-center">
        © {new Date().getFullYear()} Fooddehydratorcritic.com. All rights reserved.
      </div>
    </div>
  </footer>
);