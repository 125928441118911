import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ref, get } from 'firebase/database';
import { db } from '../../lib/firebase';
import type { Product } from '../../types';
import { Helmet } from 'react-helmet-async';

export const Brands = () => {
  const { data: products, isLoading } = useQuery({
    queryKey: ['products'],
    queryFn: async () => {
      const snapshot = await get(ref(db, 'products'));
      return snapshot.val() as Record<string, Product>;
    }
  });

  if (isLoading) {
    return <div>Loading brands...</div>;
  }

  // Group products by brand
  const brandGroups = Object.values(products || {}).reduce((acc, product) => {
    const brand = product.brand;
    if (!acc[brand]) {
      acc[brand] = [];
    }
    acc[brand].push(product);
    return acc;
  }, {} as Record<string, Product[]>);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <Helmet>
        <title>Food Dehydrator Brands | Food Dehydrator Critic</title>
        <meta name="description" content="Browse Food Dehydrators by brand - compare prices across Walmart, Amazon, and eBay" />
      </Helmet>

      <h1 className="text-3xl font-bold text-gray-900 mb-8">Food Dehyration Brands</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {Object.entries(brandGroups).map(([brand, products]) => (
          <div key={brand} className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-xl font-semibold mb-4">{brand}</h2>
            <ul className="space-y-2">
              {products.map(product => (
                <li key={product.id}>
                  <a 
                    href={`/product/${product.slug}`}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    {product.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}; 